import "ol/ol.css";
import { Style, Stroke, Fill, Icon } from 'ol/style';
import Map from "ol/Map";
import TileLayer from "ol/layer/Tile";
import { OSM } from "ol/source";
import { Feature, View, } from "ol";
import { MultiPolygon, Polygon, Point, LineString } from "ol/geom";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
/*import fetchViewportAreas from "../handlers/fetchViewportAreas";*/
import { Message } from "./Message";
import * as turf from '@turf/turf';

export class AppMap {

    static instance = null;
    static polygon = null;
    static point = [];
    static select = null;
    static features = null;
    static layerArray = [];
    static polygonArray = [];
    static ownPolygonArray = [];

    static getViewport = () => AppMap.instance?.getView().calculateExtent(AppMap.instance?.getSize());

    static fillGreen = new Style({
        stroke: new Stroke({
            color: 'rgba(46, 125, 50, 1)',
            width: 1,
        }),
        fill: new Fill({
            color: 'rgba(46, 125, 50, 0.25)', 
        }),
    });

    static fillTransparent = new Style({
        stroke: new Stroke({
            color: 'rgba(46, 125, 50, 1)',
            width: 1,
        })
    });

    static fillRed = new Style({
        fill: new Fill({
            color: 'rgba(211, 47, 47, 0.25)'
        })
    });

    static fillBackground = new Style({
        fill: new Fill({
            color: 'rgba(0, 0, 0, 0.2)'
        })
    });

    static fillOwnArea = new Style({
        stroke: new Stroke({
            color: 'rgb(46, 125, 50)',
            width: 2,
            lineDash: [5, 5],
        })
    });

    static init() {
        if (AppMap.instance) return;
        AppMap.instance = new Map({
            layers: [
                new TileLayer({
                    source: new OSM(),
                }),
            ],
            target: "map",
            view: new View({
                center: [0, 0], 
                zoom: 2, 
                enableRotation: false,
            }),
        });
        console.log("AppMap initialized");

        AppMap.instance.on('click', function(event) {

            const coordinates = event.coordinate;
            const drawnGeometry = JSON.parse(sessionStorage.getItem("drawnGeometry")) || [];
            const drawingMode = JSON.parse(sessionStorage.getItem("drawingMode"));
            
            if(drawingMode && coordinates) {

                const pointFeature = new Feature({
                    geometry: new Point(coordinates),
                });

                const pointStyle = new Style({  
                    image: new Icon({
                        src: 'https://openlayers.org/en/latest/examples/data/icon.png',
                        scale: 0.5,
                    }),
                });

                pointFeature.setStyle(pointStyle);

                const vectorSource = new VectorSource({
                    features: [pointFeature],
                });

                const vectorLayer = new VectorLayer({
                    source: vectorSource,
                });

                AppMap.instance?.addLayer(vectorLayer);
                AppMap.point.push(vectorLayer);
                drawnGeometry.push(coordinates);
                sessionStorage.setItem("drawnGeometry", JSON.stringify(drawnGeometry));
            }
        });

        AppMap.instance.on('pointermove', function(event) {

            const areaArray = JSON.parse(localStorage.getItem("areaArray"));
            const coordinates = [event.coordinate[0], event.coordinate[1]];
            const selectedAreas = [];

            if(areaArray) {
                for(let x of areaArray) {
                    const isInside = turf.booleanPointInPolygon(coordinates, x.geometry);
                    if(isInside) {
                        selectedAreas.push(x);
                    }
                }
            }
        });
    }

    static clearPolygonById(id) {
        const polygon = AppMap.ownPolygonArray.find(item => item.id === id);
        if (polygon) {
            AppMap.clearPolygon(polygon.layer);
        } else {
            console.warn(`clearPolygonById: No layer found with id ${id}`);
        }
    }

    static getZoomLevel() {
        AppMap.instance.getView().on('change:resolution', function(event) {
            const zoomLevel = AppMap.instance.getView().getZoom();
            
            const cornersArray = AppMap.instance.getView().calculateExtent();
            const corners = {
                "left bottom": [cornersArray[0], cornersArray[1]],
                "right bottom": [cornersArray[2], cornersArray[1]],
                "right top": [cornersArray[2], cornersArray[3]],
                "left top": [cornersArray[0], cornersArray[3]],
            };

            /* FETCHING VIEWPORT AREAS
            if (zoomLevel > 10) {
                const viewport = [cornersArray[1], cornersArray[0], cornersArray[3], cornersArray[2]];
                const fetching = sessionStorage.getItem('fetching') === 'true';
              
                if (!fetching) {
                    sessionStorage.setItem('fetching', true);
                    fetchViewportAreas({ viewport, zoomLevel })
                    .then((response) => {
                        sessionStorage.setItem('fetching', false);
                        const pendingRequest = JSON.parse(sessionStorage.getItem('lastPendingViewportRequest'));
                        if (pendingRequest !== null) {
                            fetchViewportAreas(pendingRequest)
                            .then((response) => {
                                sessionStorage.setItem('lastPendingViewportRequest', null);
                            })
                            .catch((error) => {
                                Message.show(error.message, Message.severity.ERROR);
                                console.error("Błąd podczas pobierania danych:", error);
                            });
                        }
                    })
                    .catch((error) => {
                        Message.show(error.message, Message.severity.ERROR);
                        console.error("Błąd podczas pobierania danych:", error);
                    });
                } else {
                    sessionStorage.setItem('lastPendingViewportRequest', JSON.stringify({ viewport, zoomLevel }));
                }
            }
            */

            sessionStorage.setItem("currentZoom", zoomLevel);
            sessionStorage.setItem("currentCorners", JSON.stringify(corners));
        });
    }

    static getCorners() {
        AppMap.instance.getView().on('change:center', function(event) {
            const cornersArray = AppMap.instance.getView().calculateExtent();
            const corners = {
                "left bottom": [cornersArray[0], cornersArray[1]],
                "right bottom": [cornersArray[2], cornersArray[1]],
                "right top": [cornersArray[2], cornersArray[3]],
                "left top": [cornersArray[0], cornersArray[3]],
            };
            const zoomLevel = sessionStorage.getItem("currentZoom");
            if(zoomLevel > 10) {
                //const viewport = [cornersArray[1], cornersArray[0], cornersArray[3], cornersArray[2]];
                //const fetching = sessionStorage.getItem('fetching') === 'true';
              
                /* FETCHING VIEWPORT AREAS
                if (!fetching) {
                  sessionStorage.setItem('fetching', true);
                  fetchViewportAreas({ viewport, zoomLevel })
                    .then(() => {
                    sessionStorage.setItem('fetching', false);
                    const pendingRequest = JSON.parse(sessionStorage.getItem('lastPendingViewportRequest'));
                    if (pendingRequest !== null) {
                        fetchViewportAreas(pendingRequest)
                        .then(() => {
                            sessionStorage.setItem('lastPendingViewportRequest', null);
                        })
                        .catch((error) => {
                            Message.show(error.message, Message.severity.ERROR);
                            console.error("Błąd podczas pobierania danych:", error);
                        });
                    }})
                    .catch((error) => {
                        Message.show(error.message, Message.severity.ERROR);
                        console.error("Błąd podczas pobierania danych:", error);
                    });
                } else {
                  sessionStorage.setItem('lastPendingViewportRequest', JSON.stringify({ viewport, zoomLevel }));
                }
                */
            }
            sessionStorage.setItem("currentCorners", JSON.stringify(corners));
        });
    }

    static clearPolygon() {
        if (AppMap.polygon) {
            AppMap.instance?.removeLayer(AppMap.polygon);
            AppMap.polygon = null;
        }
    }

    static clearPolygonByLayer(layer) {
        AppMap.instance?.removeLayer(layer);
    }

    static clearPoints() {
        if (AppMap.point) {
            for (let item of AppMap.point) {
                AppMap.instance?.removeLayer(item);
            }
            AppMap.point = [];
        }
    }

    static clearLastPoint() {
        if (AppMap.point) {
            AppMap.instance?.removeLayer(AppMap.point[AppMap.point.length - 1]);
            AppMap.point.pop();
        }
    }

    static clearFeatures() {
        if (AppMap.features) {
            AppMap.instance?.removeLayer(AppMap.features);
            AppMap.features = null;
        }
    }

    static clear(id) {

        this.clearPolygon();
        this.clearPoints();
        this.clearFeatures();

        if(id) {
            console.log("Clearing polygon with id: ", id);
            AppMap.polygonArray.forEach((item, index) => {
                if(item.id === id) {
                    console.log("Removing layer: ", item);
                    try {
                        AppMap.instance?.removeLayer(item.layer);
                    } catch (error) {
                        console.error("Error removing layer: ", error);
                    }
                    AppMap.polygonArray.splice(index, 1);
                }
            });
        }
    }

    static draw(geometry, areaStyle, zoom, background, id) {
        const drawStyle = areaStyle === "green" ? this.fillGreen :
                          areaStyle === "red" ? this.fillRed :
                          areaStyle === "background" ? this.fillBackground :
                          this.fillTransparent;

        let polygon = geometry.type === "MultiPolygon" ?
            new MultiPolygon(geometry.coordinates) :
            new Polygon(geometry.coordinates);
        let vectorSource = new VectorSource({
            features: [
                new Feature({
                    geometry: polygon,
                }),
            ],
        });
        let layer = new VectorLayer({
            source: vectorSource,
            style: [drawStyle],
        });
        /*
        let padding = new Array(4).fill(20); // top, right, bottom, left
        let sidePanel = document.querySelector('.MuiPaper-root:not(.cart-item2)');
        let parsedStyle = window.getComputedStyle(sidePanel);
        if (AppMap.instance?.getViewport().offsetWidth < 768) {
            padding[4] += sidePanel?.offsetHeight ?? 0;
            padding[4] += parseInt(parsedStyle.marginTop) + parseInt(parsedStyle.marginBottom);
        } else {
            padding[3] += sidePanel?.offsetWidth ?? 0;
            padding[3] += parseInt(parsedStyle.marginLeft) + parseInt(parsedStyle.marginRight);
        }*/

        if(zoom) {
            AppMap.instance?.getView().fit(vectorSource.getExtent(), {
                duration: 500,
                //padding: padding,
            });
        }
        AppMap.instance?.addLayer(layer);
        if(!background) AppMap.polygon = layer;
        if(!id) id = null;
        AppMap.polygonArray.push({layer: layer, id: id});
    }

    static drawMultiple(features) {

        AppMap.clear();
    
        /*
        const padding = new Array(4).fill(20); 
        const sidePanel = document.querySelector('.MuiPaper-root:not(.cart-item2)');
        const parsedStyle = window.getComputedStyle(sidePanel);
    
        if (AppMap.instance?.getViewport().offsetWidth < 768) {
            padding[2] += sidePanel?.offsetHeight ?? 0;
            padding[2] += parseInt(parsedStyle.marginTop) + parseInt(parsedStyle.marginBottom);
        } else {
            padding[3] += sidePanel?.offsetWidth ?? 0;
            padding[3] += parseInt(parsedStyle.marginLeft) + parseInt(parsedStyle.marginRight);
        }
            */

        if(this.layerArray.length > 0) {
            this.layerArray.forEach((item) => {
                AppMap.instance?.removeLayer(item);
            })
            this.layerArray = [];
        }
    
        if (features.length > 0) {
            features.forEach((feature, index) => {
                const geometry = feature.geometry;
                const polygon = geometry.type === "MultiPolygon"
                    ? new MultiPolygon(geometry.coordinates)
                    : new Polygon(geometry.coordinates);
                
                const featureId = feature.id || index.toString(); 

                let featureStyle;

                if (feature.state === 'positive') {
                    featureStyle = this.fillGreen;
                } else if (feature.state === 'negative') {
                    featureStyle = this.fillRed;
                } else if(feature.state === 'difference') {
                    featureStyle = this.fillBlue;
                }
                else {
                    featureStyle = this.fillTransparent;
                }

                const vectorSource = new VectorSource();

                let layer = new VectorLayer({
                    source: vectorSource,
                    style: featureStyle,
                });
                
                const featureObj = new Feature({
                    geometry: polygon,
                });
    
                featureObj.setId(featureId); 
                vectorSource.addFeature(featureObj);
                this.layerArray.push(layer);
            });
        }

        try {
            const temp = JSON.parse(localStorage.getItem("geometrySum")) || [];
            AppMap.draw(temp, 'transparent', true, false);
        }
        catch(error) {
            Message.show(error, Message.severity.ERROR);
            console.error("AppMap drawing error: ", error);
        }

        this.layerArray.forEach((item) => {
            AppMap.instance?.addLayer(item);
        });
    }

    static drawRoads(roads) {

        if (!AppMap.instance) {
            console.error('Map instance is not initialized.');
            return;
        }

        // Clear existing road layers if any
        if (this.layerArray.length > 0) {
            this.layerArray.forEach((item) => {
                AppMap.instance?.removeLayer(item);
            });
            this.layerArray = [];
        }

        // Create features for each road
        const features = roads.map((road, index) => {
            const lineString = new LineString(road.geometry.coordinates);

            const feature = new Feature({
                geometry: lineString,
                id: road.id || index.toString()
            });

            // Set the style based on the color property
            const roadStyle = new Style({
                stroke: new Stroke({
                    color: road.properties.color,
                    width: 3
                })
            });

            feature.setStyle(roadStyle);

            return feature;
        });

        // Log features for debugging
        console.log("Features", features);

        // Create a vector source and vector layer to hold the road features
        const vectorSource = new VectorSource({
            features: features
        });

        const vectorLayer = new VectorLayer({
            source: vectorSource
        });

        // Add the vector layer to the map
        AppMap.instance.addLayer(vectorLayer);

        // Log the added vector layer for debugging
        console.log("Adding vector layer", vectorLayer);

        // Store the layer in the layer array for future reference/cleanup
        this.layerArray.push(vectorLayer);
    }

    static clearRoads() {
        if (this.layerArray.length > 0) {
            this.layerArray.forEach((item) => {
                AppMap.instance?.removeLayer(item);
            });
            this.layerArray = [];
        }
    }

}
