import { AppMap } from "../utils/AppMap";
import { Polygon } from 'ol/geom';
import Feature from 'ol/Feature';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import * as turf from '@turf/turf';
import {Message} from "../utils/Message";
import handleAddNewAreaToAge from "./handleAddNewAreaToAge";

const addOwnArea = async (array, name, geometrySum, areaArray, setAreaArray, id, code, setPowerKW, setLoading) => {

    setLoading(true);

    try {
        const flatGeometrySum = geometrySum['coordinates'].flat();
        const intersection = areaCheck(array, flatGeometrySum);

        // Set default name
        if (name === "") name = `Obszar nr. ${areaArray.length + 1}`;

        // Remove markers
        AppMap.clearPoints();

        // Check if area intersects with any of the existing areas
        if (intersection === null) {
            Message.show("Obszar nie może być dodany, ponieważ nie przecina się z żadnym z istniejących obszarów.",
                Message.severity.ERROR);
            return;
        }

        // Check if area is a polygon
        if (intersection.geometry.type !== 'Polygon') {
            Message.show("Obszar nie jest jednolity i nie może być dodany.", Message.severity.ERROR);
            return;
        }

        const polygon = new Polygon(intersection.geometry.coordinates)

        const intersectionFeature = new Feature({
            name: name,
            geometry: polygon,
        });

        intersectionFeature.setStyle(AppMap.fillOwnArea);

        const intersectionLayer = new VectorLayer({
            source: new VectorSource({
            features: [intersectionFeature],
            }),
            className: `ol-layer ${name}`,
        });

        AppMap.instance.addLayer(intersectionLayer);

        let result;

        const requestFunction = async () => {
            await handleAddNewAreaToAge(intersection.geometry.coordinates, id, code, name);
        }
        requestFunction().then(() => {

            
            result = JSON.parse(sessionStorage.getItem("responseFeature"));
            console.log("Result of adding layer: ", result);
            console.log("Result id:", result.properties.id);

            AppMap.ownPolygonArray.push({
                id: result.properties.id,
                layer: intersectionLayer
            });            

            if (result === null) {
                Message.show("Obszar nie mógł zostać dodany.", Message.severity.ERROR);
                AppMap.instance.removeLayer(intersectionLayer);
                return;
            } else {

                const newAreaToAdd = {
                    id: areaArray.length + 1,
                    label: name,
                    admin_level: 0,
                    geometry: {
                        type: 'Polygon',
                        coordinates: intersection.geometry.coordinates,
                    },
                    state: 'neutral',
                    power_kw_min: result.properties.power_kw_min,
                    power_kw_avg: result.properties.power_kw_avg,
                    power_kw_max: result.properties.power_kw_max,
                    area_id: result.properties.id,
                    
                };
                
                setAreaArray([...areaArray, newAreaToAdd]);
            }
        });
    } catch (error) {
        console.error("Error adding area: ", error);
        Message.show("Wystąpił błąd podczas dodawania obszaru.", Message.severity.ERROR);
    }
    finally {
        setLoading(false);
    }
}

const areaCheck = (polygon1, polygon2) => {

    polygon1.push(polygon1[0]);
    polygon2.push(polygon2[0]);

    const poly1 = turf.polygon([polygon1], { name: 'poly1'});
    const poly2 = turf.polygon([polygon2], { name: 'poly2'});

    return (turf.intersect(poly1, poly2));
}

const removeOwnArea = (name, ownAreas, setOwnArea) => {

    const array = [...ownAreas];

    for (let x of array.entries()) {
        if (x[1].name === name) {
            ownAreas.splice(x[0], 1);
            if(x[1].layer) AppMap.instance.removeLayer(x[1].layer);
        }
    }
    return (ownAreas);
}

export {addOwnArea, removeOwnArea}