import { configureStore } from '@reduxjs/toolkit';
import powerKWReducer from '../features/PowerKWSlice';
import loadingReducer from '../features/LoadingSlice';
import languageReducer from '../features/LanguageSlice';
import textsSlice from '../features/TextsSlice';


const store = configureStore({
    reducer: {
        powerKW: powerKWReducer,
        loading: loadingReducer,
        language: languageReducer,
        texts: textsSlice,
    }
});

export default store;