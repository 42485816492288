import axios from "axios";
import { AppMap } from '../utils/AppMap';

const handleCalculation = async (email, cart, recaptchaToken, setCartItemsArray) => {

    if (!recaptchaToken) {
        throw new Error("Recaptcha token missing");
    }

    const itemsId = cart.map(el => el.id);

    if (email && cart) {
        const url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_REQUESTS;
        console.log(cart.map(el => el.osm_id));
        console.log(email, recaptchaToken);
        const response = await axios.post(
            url, 
            {
                email: email,
                relations: cart.map(el => el.osm_id),
                "g-recaptcha-response": recaptchaToken,
            },
            {
                headers: {
                    "Accept-Language": localStorage.getItem("language") || "pl",
                }
            }
        );

        setCartItemsArray([]);

        itemsId.forEach(id => AppMap.clear(id));

        return response;
    }

    throw new Error("Cart is empty!");
};

export default handleCalculation;
